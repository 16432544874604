<template>
  <CFooter>
    <div>
      <span class="mg-15">{{childPoolName}} © 2020
      </span>
    </div>
    <div class="ml-auto">
      <span>Staking Pool Admin</span>
    </div>
  </CFooter>
</template>

<script>
import { RoleType, env } from '@/constants';

export default {
  name: 'TheFooter',
  data() {
    return {
      childPoolName: env.theme[env.theme.name].name,
    };
  },
};
</script>
