<template>
  <div class="vld-parent">
    <loading
      :active.sync="isLoading"
      loader="dots"
      :height="100"
      :width="100"
      color="#2f3678"
      :z-index="9999"
      background-color="#c4c4c4"
    ></loading>
  </div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import { mapState } from 'vuex';

export default {
  data() {
    return {
      fullPage: true
    };
  },
  components: {
    Loading
  },
  computed: {
    ...mapState({
      isLoading() {
        const { loading } = this.$store.getters;
        return loading;
      }
    })
  },
};
</script>
