<template>
  <div class="change-language-container">
    <CDropdown inNav class="c-header-nav-items" placement="bottom-end" add-menu-classes="pt-0">
      <template #toggler>
        <CHeaderNavLink>
          <div class="language-image">
            <img
              class="language-icon"
              v-if="themeName === 'moonstake'"
              alt="Language"
              src="@/assets/images/language-icon.png"
            />
            <img
              v-if="themeName === 'binarystar'"
              alt="Language"
              src="@/assets/images/language-select-binarystar.svg"
            />
          </div>
        </CHeaderNavLink>
      </template>
      <CDropdownItem
        v-for="lang in languages"
        :key="lang.value"
        @click="chooseLang(lang)"
        :class="locale === lang.value ? 'active' : ''"
      >
        <img
          v-if="themeName === 'moonstake' && locale === lang.value"
          src="@/assets/images/check-moonstake.svg"
          class="mr-1"
        />
        <img
          v-if="themeName === 'binarystar' && locale === lang.value"
          src="@/assets/images/check-binarystar.svg"
          class="mr-1"
        />
        {{lang.name}}
      </CDropdownItem>
    </CDropdown>
  </div>
</template>

<script>
import { env, languages } from '@/constants';
import { mapState, mapGetters } from 'vuex';
import i18n, { setI18nLanguage } from '@/i18n';
import { SETTINGS_UPDATE_LOCALE } from '@/store/actions/setting';

export default {
  name: 'ChangeLanguage',
  data() {
    return {
      languages: languages,
      themeName: env.theme.name,
    };
  },
  computed: {
    ...mapGetters(['locale']),
  },
  mounted() {
    this.setLocale();
  },
  methods: {
    setLocale() {
      setTimeout(() => {
        setI18nLanguage(this.locale);
      });
    },
    chooseLang(lang) {
      const locale = lang.value;
      this.$store.commit('SETTINGS_UPDATE_LOCALE', locale);

      // setI18nLanguage(locale);
      this.reload();
    },
    reload() {
      window.location.reload(true);
    },
  },
};
</script>
<style lang="scss" scoped>
.change-language-container {
  background-color: transparent;
  display: inline-block;
  .language-icon {
    width: 20px;
    height: auto;
  }
  &.change-language-container-login-page {
    background-color: #fff;
    position: absolute;
    top: 100px;
    right: 100px;
    padding: 10px;
    border: 1px solid #ccc;
    .nav-item {
      display: block;
    }
  }

  .language-image {
  }

  .dropdown-item.active {
    color: #4d5666;
    background-color: transparent;
  }
}
</style>
