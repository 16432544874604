import membershipManagementIcon from '@/assets/images/membership-management.svg';
import childpoolIcon from '@/assets/images/childpool2.svg';
import membersIcon from '@/assets/images/members.svg';
import operatorManamentIcon from '@/assets/images/operator-manageent.svg';
import membershipSettingsIcon from '@/assets/images/membership-settings.svg';
import affiliateSettingsIcon from '@/assets/images/affiliate-settings.svg';
import childpoolSettingsIcon from '@/assets/images/child-pool-settings.svg';
import payoutIcon from '@/assets/images/affiliate-mgmt.svg';
import cryptoAddressesIcon from '@/assets/images/qr-code.svg';
import exchangeIcon from '@/assets/images/exchange.svg';
import memberAssetsIcon from '@/assets/images/member-assets.svg';
import allAssetsIcon from '@/assets/images/all-assets.svg';
import stakingIcon from '@/assets/images/staking-info.svg';

export default [
  {
    _name: 'CSidebarNav',
    _children: [
      {
        _name: 'CSidebarNavItem',
        name: 'MEMBERSHIP_MENU_COMPONENT_CHILDPOOL',
        key: '01-CHILDPOOL',
        to: '/admin/childpool',
        permissionName: 'VIEW_LIST_CHILDPOOL_GRANDCHILD',
        icon: {
          src: childpoolIcon,
          class: 'c-sidebar-nav-icon'
        },
      },
      {
        _name: 'CSidebarNavItem',
        name: 'MEMBERSHIP_MENU_COMPONENT_MEMBER',
        key: '02-MEMBER_LIST',
        to: '/admin/members',
        permissionName: 'MEMBERSHIP_VIEW_MEMBER_LIST',
        icon: {
          src: membersIcon,
          class: 'c-sidebar-nav-icon'
        },
      },
      {
        _name: 'CSidebarNavItem',
        name: 'MEMBERSHIP_MENU_COMPONENT_CRYPTO_ADDRESSES',
        key: '03-CRYPTO_ADDRESSES',
        to: '/admin/crypto-addresses',
        permissionName: 'VIEW_LIST_CRYPTO_ADDRESS',
        icon: {
          src: cryptoAddressesIcon,
          class: 'c-sidebar-nav-icon'
        },
      },
      {
        _name: 'CSidebarNavItem',
        name: 'MEMBERSHIP_MENU_COMPONENT_USER_ASSETS',
        key: '13-USER_ASSETS',
        to: '/admin/member-assets',
        permissionName: 'VIEW_LIST_MEMBER_ASSET',
        icon: {
          src: memberAssetsIcon,
          class: 'c-sidebar-nav-icon'
        },
      },
      {
        _name: 'CSidebarNavItem',
        name: 'MEMBERSHIP_MENU_COMPONENT_ALL_ASSETS',
        key: '13-ALL_ASSETS',
        to: '/admin/moonstake-assets',
        permissionName: 'VIEW_ALL_ASSETS',
        icon: {
          src: allAssetsIcon,
          class: 'c-sidebar-nav-icon'
        },
      },
      {
        _name: 'CSidebarNavDropdown',
        name: 'MEMBERSHIP_MENU_COMPONENT_GROUP_MEMBERSHIP_MANAGEMENT',
        key: '04-MEMBERSHIP-MANAGEMENT',
        route: '/admin/Membership-management',
        dropdownClasses: '/admin/Membership-management',
        icon: {
          src: membershipManagementIcon,
          class: 'c-sidebar-nav-icon'
        },
        items: [
          {
            _name: 'CSidebarNavItem',
            name: 'MEMBERSHIP_MENU_COMPONENT_MEMBERSHIP_ORDERS',
            key: 'MEMBERSHIP_ORDERS',
            to: '/admin/membership-orders',
            permissionName: 'MEMBERSHIP_VIEW_ORDER_LIST',
          },
          {
            _name: 'CSidebarNavItem',
            name: 'MEMBERSHIP_MENU_COMPONENT_CLAIM_REQUESTS',
            key: 'CLAIM_REQUESTS',
            to: '/admin/commission-payout',
            permissionName: 'MEMBERSHIP_VIEW_CLAIM_REQUEST_LIST',
          },
          {
            _name: 'CSidebarNavItem',
            name: 'MEMBERSHIP_MENU_COMPONENT_EXCHANGE_RATES',
            key: 'EXCHANGE_RATES',
            to: '/admin/exchange-rates',
            permissionName: 'MEMBERSHIP_VIEW_FIAT_RATE',
          },
          {
            _name: 'CSidebarNavItem',
            name: 'MEMBERSHIP_MENU_COMPONENT_MS_POINT_HISTORIES',
            key: 'MS_POINT_HISTORIES',
            to: '/admin/ms-point-histories',
            permissionName: 'VIEW_CLAIM_MS_POINT_HISTORIES',
          },
        ]
      },
      {
        _name: 'CSidebarNavDropdown',
        name: 'MEMBERSHIP_MENU_COMPONENT_GROUP_AFFILIATE_MANAGEMENT',
        key: '05-AFFILIATE-MANAGEMENT',
        // route: '/admin/Membership-management',
        icon: {
          src: payoutIcon,
          class: 'c-sidebar-nav-icon'
        },
        items: [
          {
            _name: 'CSidebarNavItem',
            name: 'MEMBERSHIP_MENU_COMPONENT_TOKEN_PAYOUT',
            key: 'Token Payout',
            to: '/admin/affiliate-management/token-payout',
            permissionName: 'AFFILIATE_TOKEN_PAYOUT_VIEW_LIST',
          },
          {
            name: 'MEMBERSHIP_MENU_COMPONENT_MEMBERSHIP_VIEW_CACULATE_REWARD_REQUEST',
            permissionName: 'MEMBERSHIP_VIEW_CACULATOR_REWARD_REQUEST_LIST',
            key: 'MEMBERSHIP_VIEW_CACULATOR_REWARD_REQUEST_LIST',
            to: '/admin/affiliate-management/reward-requests',
          },
          {
            name: 'MEMBERSHIP_MENU_COMPONENT_MEMBERSHIP_VIEW_TOP_AFFILIATE',
            permissionName: 'VIEW_TOP_AFFILIATE',
            key: 'VIEW_TOP_AFFILIATE',
            to: '/admin/affiliate-management/top-affiliate',
          },
        ]
      },
      {
        _name: 'CSidebarNavDropdown',
        name: 'MEMBERSHIP_MENU_COMPONENT_GROUP_SETTING_EXCHANGE',
        key: '08-EXCHANGE',
        route: '/admin/exchange',
        icon: {
          src: exchangeIcon,
          class: 'c-sidebar-nav-icon'
        },
        items: [
          {
            name: 'MEMBERSHIP_MENU_COMPONENT_MEMBERSHIP_VIEW_EXCHANGE_CURRENCIES',
            permissionName: 'VIEW_EXCHANGE_CURRENCIES',
            key: 'VIEW_EXCHANGE_CURRENCIES',
            to: '/admin/exchange/currencies',
          },
          {
            name: 'MEMBERSHIP_BREADCRUMB_COMPONENT_VIEW_EXCHANGE_TRANSACTIONS',
            permissionName: 'VIEW_LIST_EXCHANGE_TRANSACTION',
            key: 'VIEW_LIST_EXCHANGE_TRANSACTION',
            to: '/admin/exchange/transactions',
          },
          {
            name: 'MEMBERSHIP_BREADCRUMB_COMPONENT_VIEW_LIST_FIAT_TRANSACTION',
            permissionName: 'VIEW_LIST_FIAT_TRANSACTION',
            key: 'VIEW_LIST_FIAT_TRANSACTION',
            to: '/admin/exchange/fiat-transactions',
          },
          {
            name: 'MEMBERSHIP_MENU_COMPONENT_MEMBERSHIP_VIEW_MOONPAY_CURRENCIES',
            permissionName: 'VIEW_EXCHANGE_CURRENCIES',
            key: 'VIEW_EXCHANGE_CURRENCIES',
            to: '/admin/exchange/moonpay-currencies',
          }
          // {
          //   name: 'MEMBERSHIP_BREADCRUMB_COMPONENT_VIEW_LIST_NEXO_TRANSACTION',
          //   permissionName: 'VIEW_LIST_NEXO_TRANSACTION',
          //   key: 'VIEW_LIST_NEXO_TRANSACTION',
          //   to: '/admin/exchange/nexo-transactions',
          // },
          // {
          //   name: 'MEMBERSHIP_BREADCRUMB_COMPONENT_VIEW_LIST_NEXO_MEMBER',
          //   permissionName: 'VIEW_LIST_NEXO_MEMBER',
          //   key: 'VIEW_LIST_NEXO_MEMBER',
          //   to: '/admin/exchange/nexo-members',
          // },
        ]
      },
      {
        _name: 'CSidebarNavItem',
        name: 'MEMBERSHIP_MENU_COMPONENT_STAKING_DAO',
        key: '14-STAKING_DAO',
        to: '/admin/staking-dao',
        permissionName: 'VIEW_LIST_STAKING_DAO_INSTANCE',
        icon: {
          src: stakingIcon,
          class: 'c-sidebar-nav-icon'
        },
      },
      {
        _name: 'CSidebarNavItem',
        name: 'MEMBERSHIP_MENU_COMPONENT_GROUP_SETTING',
        key: 'MEMBERSHIP_MENU_COMPONENT_GROUP_SETTING',
        addLinkClasses: 'link-settings-sidebar'
      },
      {
        _name: 'CSidebarNavDropdown',
        name: 'MEMBERSHIP_MENU_COMPONENT_GROUP_SETTING_MEMBERSHIP_MEMBERSHIP_SETTING',
        key: '07-MEMBERSHIP-SETTINGS',
        route: '/admin/membership-settings',
        icon: {
          src: membershipSettingsIcon,
          class: 'c-sidebar-nav-icon'
        },
        items: [
          {
            name: 'MEMBERSHIP_MENU_COMPONENT_MEMBERSHIP_VIEW_BANK_ACCOUNT_REWARD',
            permissionName: 'MEMBERSHIP_VIEW_BANK_ACCOUNT_REWARD',
            key: 'MEMBERSHIP_VIEW_BANK_ACCOUNT_REWARD',
            to: '/admin/membership-settings/bank-account',
          },
          {
            name: 'MEMBERSHIP_MENU_COMPONENT_MEMBERSHIP_VIEW_MEMBERSHIP_POLICY_LIST',
            permissionName: 'MEMBERSHIP_VIEW_MEMBERSHIP_POLICY_LIST',
            key: 'MEMBERSHIP_VIEW_MEMBERSHIP_POLICY_LIST',
            to: '/admin/membership-settings/membership-payout-policy',
          },
          {
            name: 'MEMBERSHIP_MENU_COMPONENT_MEMBERSHIP_VIEW_MEMBERSHIP_TYPE_CONFIG',
            permissionName: 'MEMBERSHIP_VIEW_MEMBERSHIP_TYPE_CONFIG',
            key: 'MEMBERSHIP_VIEW_MEMBERSHIP_TYPE_CONFIG',
            to: '/admin/membership-settings/membership-type',
          },
          {
            name: 'MEMBERSHIP_MENU_COMPONENT_MEMBERSHIP_VIEW_RECEIVING_ADDRESS',
            permissionName: 'MEMBERSHIP_VIEW_RECEIVING_ADDRESS',
            key: 'MEMBERSHIP_VIEW_RECEIVING_ADDRESS',
            to: '/admin/membership-settings/receiving-address',
          },
          {
            name: 'MEMBERSHIP_MENU_COMPONENT_MEMBERSHIP_VIEW_CLAIM_MEMBERSHIP_REWARD_SETTING',
            permissionName: 'MEMBERSHIP_VIEW_CLAIM_MEMBERSHIP_REWARD_SETTING',
            key: 'MEMBERSHIP_VIEW_CLAIM_MEMBERSHIP_REWARD_SETTING',
            to: '/admin/membership-settings/claim-membership-reward-setting',
          },
          // {
          //   name: 'MEMBERSHIP_MENU_COMPONENT_MEMBERSHIP_VIEW_UPGRADE_CONDITION',
          //   permissionName: 'MEMBERSHIP_VIEW_UPGRADE_CONDITION',
          //   key: 'MEMBERSHIP_VIEW_UPGRADE_CONDITION',
          //   to: '/admin/membership-settings/upgrade-condition',
          // },

        ]
      },
      {
        _name: 'CSidebarNavDropdown',
        name: 'MEMBERSHIP_MENU_COMPONENT_GROUP_SETTING_AFFILIATE_SETTINGS',
        key: '08-AFFILIATE-SETTINGS',
        route: '/admin/affiliate-configuration',
        icon: {
          src: affiliateSettingsIcon,
          class: 'c-sidebar-nav-icon'
        },
        items: [
          {
            name: 'MEMBERSHIP_MENU_COMPONENT_MEMBERSHIP_VIEW_AFFILIATE_POLICY_LIST',
            permissionName: 'MEMBERSHIP_VIEW_AFFILIATE_POLICY_LIST',
            key: 'MEMBERSHIP_VIEW_AFFILIATE_POLICY_LIST',
            to: '/admin/affiliate-configuration/affiliate-policies',
          },
          {
            name: 'MEMBERSHIP_MENU_COMPONENT_AFFILIATE_VIEW_CLAIM_REWARD_SETTING',
            permissionName: 'AFFILIATE_VIEW_CLAIM_REWARD_SETTING',
            key: 'AFFILIATE_VIEW_CLAIM_REWARD_SETTING',
            to: '/admin/affiliate-configuration/claim-affiliate-reward-setting',
          },
        ]
      },
      {
        _name: 'CSidebarNavItem',
        name: 'MEMBERSHIP_MENU_COMPONENT_GROUP_SETTING_OPERATOR_MANAGEMENT',
        key: '09-OPERATOR_MANAGEMENT',
        to: '/admin/user-management',
        permissionName: 'VIEW_LIST_USER',
        icon: {
          src: operatorManamentIcon,
          class: 'c-sidebar-nav-icon'
        },
      },
      {
        _name: 'CSidebarNavItem',
        name: 'MEMBERSHIP_MENU_COMPONENT_GROUP_SETTING_ROLE_MANAGEMENT',
        to: '/admin/roles',
        permissionName: 'ROOT',
        key: '10-ROLE_MANAGEMENT',
        icon: 'cil-puzzle'
      },
      {
        _name: 'CSidebarNavDropdown',
        name: 'MEMBERSHIP_MENU_COMPONENT_GROUP_SETTING_CHILDPOOL_SETTING',
        key: '11-CHILD-POOL-SETTING',
        route: '/admin/setting',
        icon: {
          src: childpoolSettingsIcon,
          class: 'c-sidebar-nav-icon'
        },
        items: [
          {
            name: 'MEMBERSHIP_MENU_COMPONENT_CREATE_CHANGE_REWARD_ADDRESS_REQUEST',
            permissionName: 'CREATE_CHANGE_REWARD_ADDRESS_REQUEST',
            key: 'CREATE_CHANGE_REWARD_ADDRESS_REQUEST',
            to: '/admin/setting/commission-address'
          },
          {
            name: 'EMAIL_TEMPLATE_PAGE_MENU_LABEL',
            permissionName: 'VIEW_EMAIL_TEMPLATE_LIST',
            key: 'VIEW_EMAIL_TEMPLATE_LIST',
            to: '/admin/setting/email-templates'
          },
          {
            name: 'MEMBERSHIP_MENU_COMPONENT_TERM_LIST',
            permissionName: 'VIEW_LIST_TERM',
            key: 'VIEW_LIST_TERM',
            to: '/admin/setting/terms'
          },
          {
            name: 'MEMBERSHIP_MENU_COMPONENT_VIEW_ADA_POOL_NOTIFY_CONFIG',
            permissionName: 'VIEW_ADA_POOL_NOTIFY_CONFIG',
            key: 'VIEW_ADA_POOL_NOTIFY_CONFIG',
            to: '/admin/setting/ada-notify-config'
          },
          {
            name: 'MEMBERSHIP_MENU_COMPONENT_VIEW_LIST_CURRENCY',
            permissionName: 'VIEW_LIST_CURRENCY',
            key: 'VIEW_LIST_CURRENCY',
            to: '/admin/setting/currencies'
          },
          {
            name: 'MEMBERSHIP_MENU_COMPONENT_VIEW_LIST_EMAIL_TRACKING',
            permissionName: 'VIEW_LIST_EMAIL_TRACKING',
            key: 'VIEW_LIST_EMAIL_TRACKING',
            to: '/admin/setting/tracking-emails'
          },
          {
            name: 'SETTING_MENU_COMPONENT_VIEW_LIST_NOTIFICATION',
            permissionName: 'VIEW_NOTIFICATIONS',
            key: 'VIEW_NOTIFICATIONS',
            to: '/admin/setting/notification'
          },
          {
            name: 'SETTING_MENU_COMPONENT_VIEW_CLAIM_MS_POINT_SETTINGS',
            permissionName: 'VIEW_MS_POINT_SETTINGS',
            key: 'VIEW_MS_POINT_SETTINGS',
            to: '/admin/setting/ms-point'
          },
          {
            name: 'MEMBERSHIP_MENU_COMPONENT_LOGGING',
            key: 'VIEW_LOGGINGS',
            to: '/admin/setting/logging',
            permissionName: 'VIEW_LOGGINGS',
          },
          {
            name: 'MEMBERSHIP_MENU_COMPONENT_VIEW_IOST_CAMPAIGN_DETAIL',
            permissionName: 'VIEW_CAMPAIGN_DETAIL',
            key: 'VIEW_CAMPAIGN_DETAIL',
            to: '/admin/setting/iost-campaign-config'
          },
          {
            name: 'MEMBERSHIP_MENU_COMPONENT_DAPP',
            permissionName: 'VIEW_DAPP_LIST',
            key: 'VIEW_DAPP_LIST',
            to: '/admin/setting/dapp'
          },
        ]
      },
      // {
      //   _name: "CSidebarNavItem",
      //   name: "Download CoreUI",
      //   href: "http://coreui.io/vue/",
      //   icon: { name: "cil-cloud-download", class: "text-white" },
      //   _class: "bg-success text-white",
      //   target: "_blank"
      // },
      // {
      //   _name: "CSidebarNavItem",
      //   name: "Try CoreUI PRO",
      //   href: "http://coreui.io/pro/vue/",
      //   icon: { name: "cil-layers", class: "text-white" },
      //   _class: "bg-danger text-white",
      //   target: "_blank"
      // },
    ]
  }
];
