<template>
  <CSidebar fixed :minimize="minimize" :show.sync="show">
    <CSidebarBrand to="/admin" class="side-bar">
      <img
        v-if="themeName === 'moonstake' && minimize === false"
        src="@/assets/images/themes/moonstake/logo-moonstake-white.svg"
        class="moonstake-logo"
        alt="MoonStake"
      />

      <img
        v-if="themeName === 'moonstake' && minimize === true"
        src="@/assets/images/themes/moonstake/logo-moonstake-white.svg"
        class="moonstake-small-logo"
        alt="MoonStake"
      />

      <img
        v-if="themeName === 'binarystar' && minimize === false"
        src="@/assets/images/themes/binarystar/binarystar-horizontal-wordmark.svg"
        class="binarystar-logo"
        alt="Binarystar"
      />

      <img
        v-if="themeName === 'binarystar' && minimize === true"
        src="@/assets/images/themes/binarystar/binarystar-brand-logo-no-text.svg"
        class="binarystar-small-logo"
        alt="Binarystar"
      />

      <!-- <span v-else>B</span> -->
    </CSidebarBrand>
    <CRenderFunction flat :content-to-render="nav" />
    <CSidebarMinimizer class="d-md-down-none" @click.native="minimize = !minimize" />
  </CSidebar>
</template>
<script>
import _ from 'lodash';
import { mapState, mapActions } from 'vuex';
import nav from './_nav';
import { endpoints, RoleType, env } from '@/constants';
import responseHelper from '@/utils/response-helper';

export default {
  name: 'TheSidebar',
  data() {
    return {
      themeName: env.theme.name,
      minimize: false,
      nav: [],
      show: 'responsive',
    };
  },
  computed: {
    ...mapState({
      role: (state) => (state.auth ? state.auth.role : null),
      userPermissions: (state) => (state.auth ? state.auth.userPermissions : null),
    }),
  },
  created() {
    this.unwatch = this.$store.watch(
      (state, getters) => getters.userPermissions,
      (newValue, oldValue) => {
        const userPermissions = newValue || [];

        this.updateSidebar(userPermissions);
      },
    );

    this.updateSidebar(this.userPermissions);
  },
  beforeDestroy() {
    this.unwatch();
  },
  async mounted() {
    this.$root.$on('toggle-sidebar', () => {
      const sidebarOpened = this.show === true || this.show === 'responsive';
      this.show = sidebarOpened ? false : 'responsive';
    });
    this.$root.$on('toggle-sidebar-mobile', () => {
      const sidebarClosed = this.show === 'responsive' || this.show === false;
      this.show = sidebarClosed ? true : 'responsive';
    });
  },
  methods: {
    updateSidebar(userPermissions) {
      if (!userPermissions.length) {
        this.nav = [];
        return;
      }

      const cachePermission = userPermissions.reduce((result, item) => {
        result[item.name] = result;

        return result;
      }, {});

      nav[0]._children.forEach((i) => {
        if (!i.oldName) {
          i.oldName = i.name;
        }
        i.name = this.$t(i.oldName);

        if (i.items) {
          i.items.forEach((e) => {
            if (!e.oldName) {
              e.oldName = e.name;
            }
            e.name = this.$t(e.oldName);
          });
        }
      });

      const nav2 = _.cloneDeep(nav);
      const menuItems = nav2[0]._children;
      this.removeMenuItems(menuItems, cachePermission);

      const lastMenuItem = _.last(menuItems);
      if (lastMenuItem.key === 'MEMBERSHIP_MENU_COMPONENT_GROUP_SETTING') {
        _.remove(menuItems, (e) => e.key === 'MEMBERSHIP_MENU_COMPONENT_GROUP_SETTING');
      }

      this.nav = nav2;
    },
    removeMenuItems(menuItems, cachePermission) {
      for (let index = 0; index < menuItems.length; ) {
        const item = menuItems[index];
        const { permissionName, key, items } = item;
        // console.log(index, permissionName, key, !!cachePermission[permissionName]);
        if (permissionName && !cachePermission[permissionName]) {
          _.remove(menuItems, (e) => e.key === key);
          continue;
        }

        if (!items) {
          index++;
          continue;
        }

        this.removeMenuItems(items, cachePermission);
        if (!items.length) {
          _.remove(menuItems, (e) => e.key === key);
        } else {
          index++;
        }
      }
    },
  },
};
</script>
<style lang="scss">
.moonstake-small-logo {
  width: 60px;
}
.binarystar-logo {
  height: 20px;
}

.binarystar-small-logo {
  width: 45px;
}

.side-bar {
  font-size: 30px;
  font-weight: bold;
  flex: 0 0 60px;
}
.c-sidebar {
  .c-sidebar-brand {
    background-color: #1a2958;
  }
  .c-sidebar-nav {
    background-color: #3b4b7e;
    .c-sidebar-nav-link {
      font-size: 14px;
      font-weight: 300;
      letter-spacing: 0.5px;
    }
    .c-sidebar-nav-link:hover,
    .c-sidebar-nav-dropdown-toggle:hover {
      background: rgba(255, 255, 255, 0.15);
    }

    .link-settings-sidebar {
      border-top: 1px solid #1a2958;
    }
  }
}
</style>
